// extracted by mini-css-extract-plugin
export var appLinks = "blog-post-module--appLinks--78ed2";
export var blogPost = "blog-post-module--blogPost--72ffc";
export var bottomDescription = "blog-post-module--bottomDescription--f4773";
export var description = "blog-post-module--description--74585";
export var divImageArticle = "blog-post-module--divImageArticle--7b842";
export var heroImage = "blog-post-module--heroImage--58934";
export var heroImageWrapper = "blog-post-module--heroImageWrapper--43181";
export var icon = "blog-post-module--icon--7154a";
export var icon2 = "blog-post-module--icon2--c0641";
export var iconText = "blog-post-module--iconText--0ec6d";
export var imageArticle = "blog-post-module--imageArticle--e6305";
export var richTextContainer = "blog-post-module--richTextContainer--7caea";
export var sectionTitle = "blog-post-module--sectionTitle--023ae";
export var title = "blog-post-module--title--59a7d";
export var topContainer = "blog-post-module--topContainer--779ca";
export var topInformations = "blog-post-module--topInformations--0b5d4";
export var whiteSeparator = "blog-post-module--whiteSeparator--74719";
export var whiteSeparator2 = "blog-post-module--whiteSeparator2--793ad";