import React from 'react'
import Typography from '../components/ui/typography'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import * as bpstyles from './blog-post.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import '../pages/base.css'
import Layout from '../components/website_layout/layout'
import Section from '../components/ui/section'
import Container from '../components/ui/container'
import ShareButtons from '../components/share_buttons/share_buttons'

import readingTime from 'reading-time'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import calendar from '../../static/assets/img/calendar_white.svg'
import clock from '../../static/assets/img/clock_white.svg'
import AppLinks from '../components/app_links/app_links'
import { assetNames } from '../utilities.js/constants'

const { Paragraph } = Typography

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }
      return (
        <div className={bpstyles.divImageArticle}>
          <GatsbyImage
            alt={node.data.target.title}
            image={gatsbyImageData}
            className={bpstyles.imageArticle}
            imgStyle={{ objectFit: 'contain' }}
          />
          {/* <div className="h-5" /> */}
        </div>
      )
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return (
        <ol>
          <div className="h-5" />
          {children.map((child, idx) => (
            <li key={idx}>
              <span key={idx}>
                {idx + 1}. {child}
              </span>
            </li>
          ))}
          <div className="h-5" />
        </ol>
      )
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ol>
        <div className="h-5" />
        {children.map((child, idx) => (
          <li key={idx}>
            <span key={idx}>• {child}</span>
          </li>
        ))}
        <div className="h-5" />
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <span>
        {children.map((child, idx) => (
          <span key={idx}>{child}</span>
        ))}
      </span>
    ),
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="mt-6 mb-3">{children}</h1>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="mt-6 mb-3">{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="mt-3 mb-3">{children}</h3>
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className="mt-3 mb-3">{children}</h4>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <span>
          {children}
          {/* Gap between new lines in paragraphs */}
          <div className='mb-3' />
        </span>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <span>
          <a className="text-blue" href={node.data.uri}>
            {children}
          </a>
        </span>
      )
    },
  },

  renderText: (text) => {
    if (text.startsWith('##')) {
      return (
        <Paragraph className={bpstyles.sectionTitle} responsive={true}>
          {text.replace('##', '')}
          {' XXXX '}
        </Paragraph>
      )
    }
    return text
  },
}

// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    if (isBrowser) {
      const post = get(this.props, 'data.contentfulBlogPostV4Website')
      const url = `https://www.wagetap.com/blog/${post.slug}`
      // Track seeing this page
      window.analytics.track('blog_post_viewed', {
        title: post.title,
        url,
      })
    }
  }

  render() {
    const assets = assetNames.reduce((res, assetName) => {
      res[assetName] = this.props.data[assetName]?.gatsbyImageData
      return res
    }, {})

    const pageContent = get(this, 'props.data.contentfulWageAdvancePageContent')

    const post = get(this.props, 'data.contentfulBlogPostV4Website')
    const readingtext = documentToPlainTextString(JSON.parse(post.body.raw))
    const readingtime = readingTime(readingtext)

    const url = `https://www.wagetap.com/blog/${post.slug}`
    const twitterHandle = 'wagetap'
    const tags = post.tags

    const siteMetadata = get(this, 'props.data.contentfulSiteMetadata')
    // Override for page meta and title
    siteMetadata.title = post.titletag
    siteMetadata.description = post.descriptiontag

    return (
      <>
        <Layout data={pageContent} seo={siteMetadata} assets={assets}>
          <div className={bpstyles.topContainer}>
            <h1 className={bpstyles.title}>{post.title}</h1>
            <div className={bpstyles.topInformations}>
              <img src={calendar} alt="calendar" className={bpstyles.icon} />
              <p className={bpstyles.iconText}>{post.publishDate}</p>
              <img src={clock} alt="clock" className={bpstyles.icon2} />
              <p className={bpstyles.iconText}>
                {/* {estimateReadingTime(props.blogItem.numberOfWords)} */}
                {readingtime.text}
              </p>
            </div>
            <div className="flex-col flex items-center">
              <div className={bpstyles.heroImageWrapper}>
                <GatsbyImage
                  className={bpstyles.heroImage}
                  alt={post.heroImage.title}
                  image={post.heroImage.gatsbyImageData}
                />
              </div>
            </div>
          </div>
          <Section className="bg-white">
            <div className={bpstyles.whiteSeparator}></div>
            <Container className={bpstyles.richTextContainer}>
              <h4 className={bpstyles.description}>
                {post.description.description}
              </h4>
              <div className={bpstyles.blogPost}>
                {renderRichText(post.body, options)}
              </div>
              <div className={bpstyles.whiteSeparator2}></div>
              <h4 className={bpstyles.bottomDescription}>
                For additional help in improving your spending habits, you can
                always download Wagetap. It is a leading wage advance and bill
                split app that allows you to access your pay early. Emergencies
                can always happen and Wagetap can help you handle life's
                unexpected expenses.
              </h4>
              <div className={bpstyles.appLinks}>
                <AppLinks assets={assets} browserLink={false} isBlack={true} />
              </div>
              <ShareButtons
                title={post.title}
                url={url}
                twitterHandle={twitterHandle}
                tags={tags}
              />
            </Container>
          </Section>
        </Layout>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPostV4Website(slug: { eq: $slug }) {
      title
      titletag
      descriptiontag
      slug
      description {
        description
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        gatsbyImageData(width: 1180)
        title
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            title
          }
        }
      }
      tags
    }
    contentfulWageAdvancePageContent(
      contentful_id: { eq: "6msPFa337Jh4VoxIaP68Sk" }
    ) {
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section2Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }

        section3Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
