import React from 'react'
import * as styles from './share_buttons.module.css'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'

// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

function trackShare(opts) {
  if (isBrowser) {
    // Track seeing this page
    window.analytics.track('blog_post_share_clicked', opts)
  }
}

const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <div className="flex flex-col">
      <h4 className={styles.title}>Share this post</h4>
      <div className="flex flex-row gap-3">
        <FacebookShareButton
          url={url}
          beforeOnClick={() =>
            trackShare({
              title,
              url,
              platform: 'facebook',
            })
          }
        >
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>

        <TwitterShareButton
          url={url}
          title={title}
          via={twitterHandle}
          hashtags={tags}
          beforeOnClick={() =>
            trackShare({
              title,
              url,
              platform: 'twitter',
            })
          }
        >
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>

        <LinkedinShareButton
          url={url}
          beforeOnClick={() =>
            trackShare({
              title,
              url,
              platform: 'linkedin',
            })
          }
        >
          <LinkedinIcon size={40} round={true} />
        </LinkedinShareButton>

        <RedditShareButton
          url={url}
          title={title}
          beforeOnClick={() =>
            trackShare({
              title,
              url,
              platform: 'reddit',
            })
          }
        >
          <RedditIcon size={40} round={true} />
        </RedditShareButton>

        <WhatsappShareButton
          url={url}
          title={title}
          beforeOnClick={() =>
            trackShare({
              title,
              url,
              platform: 'whatsapp',
            })
          }
        >
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}
export default ShareButtons
